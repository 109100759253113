import React, { Component } from 'react'
import Seo from '../components/seo'
import { Link } from 'gatsby'
import Selector from './qc-selector'
import Invoice from './invoice'
import GlbLink from './glb-link'
import JSONDownload from './json-download'
import {
  initSelections,
  selectionsFromProductObject,
  buildSayduckSelections,
  treeSelector,
  findSelectedLimits,
  getSmartCodeAndDescription,
  getSelectedDimensions,
  filterExternalDocuments
} from '../utils/selectors'
import {
  mergeHardwareKitsIntoPricing
} from '../utils/pricing'
import {
  getPublicProduct,
  resetCamera,
  takePhoto
} from '../utils/sayduck'
import { Script } from 'gatsby'
var cloneDeep = require('lodash.clonedeep')
const isBrowser = typeof window !== `undefined`
let api;

class QC extends Component {
  constructor(props) {
    super(props)
    let product = this.props.context?.frontmatter
    console.log(product)
    product = mergeHardwareKitsIntoPricing(product, this.props.context.hardware_kits)
    let selectors = []
    let limits = []
    let details = []
    let documents = []
    let lightsOn = false
    let shadowSelectors = null
    let descriptionOverrides = []
    let defaultConfiguration = ''
    let lightsOffTriggers = []
    let metaData = {
      smartCode: []
    }
    let dimensions = ''
    let dimensionOverrides = {
      overrides: (product.dimension_overrides ? product.dimension_overrides : [] ),
      overrideIndex: (product.dimension_override_index ? product.dimension_override_index : 1 )
    }
    if(product.description_overrides){
      descriptionOverrides = product.description_overrides
    }
    if (product.uses_light){
      lightsOn = false
    }
    if(product?.configurator_components){
      if(product.shadow_id){
        shadowSelectors  = {
          id: product.shadow_id,
          off_id: product?.shadow_off_id,
          shadows: product.shadows
        }
      }
      let productDefault = []
      if(product.default_configuration){
        productDefault = product.default_configuration.split('-')
      }
      if(product?.lights_off_triggers){
        if(product.lights_off_triggers.length > 0){
          lightsOffTriggers = product.lights_off_triggers
        }
      }
      product = initSelections(product, productDefault)
      selectors = selectionsFromProductObject(product)
      limits = findSelectedLimits(selectors)
      dimensions = getSelectedDimensions(selectors, dimensionOverrides)
      metaData = getSmartCodeAndDescription(selectors, dimensions, descriptionOverrides)
      details = buildSayduckSelections(selectors)
      delete details[product.configurator_id]
      let detailOutput = {}
      for (const key in details) {
        const value = details[key];
        detailOutput[key] = value
      }
      if(this.props.shadowSelectors){
        detailOutput[this.props.shadowSelectors.id] = this.props.shadowSelectors.off_id
      }
      let detailOutputString = JSON.stringify(detailOutput)
      defaultConfiguration = detailOutputString
      documents = filterExternalDocuments(product.external_documents, metaData)
    }
    this.sayduckViewer = React.createRef()
    this.state = {
      product: product,
      categoryPath: product.category_path,
      selectors: selectors,
      limits: limits,
      metaData: metaData,
      documents: documents,
      dimensions: dimensions,
      pricingOptions: product.pricing_options,
      details: details,
      liveVariants: [],
      dimensionOverrides: dimensionOverrides,
      descriptionOverrides: descriptionOverrides,
      defaultConfiguration: defaultConfiguration,
      usdzLink: '',
      glbLink: '',
      lightsOn: lightsOn,
      quantity: 1,
      regenImage: true,
      launchPDF: false,
      image: null,
      notes: '',
      listenerAdded: false,
      shadowSelectors: shadowSelectors,
      lightsOffTriggers: lightsOffTriggers
    }
    if (isBrowser) {
      getPublicProduct(product.configurator_id).then(liveVariants => {
        this.setState({
          liveVariants: liveVariants
        }, function(){
          // click the light button off if it exists
          setTimeout(function(){
            let buttons = document.getElementsByClassName('sayduck-3d-viewer-164 sayduck-3d-viewer-158 sayduck-3d-viewer-143 sayduck-3d-viewer-144')
            if(buttons){
              if(buttons.length > 1){
                buttons[1].click()
              }
            }
          },1000)
        })
      })
      setTimeout(function(){
        window.dispatchEvent(
          new CustomEvent(
            'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
          )
        )
      },500)
      setTimeout(function(){
        window.dispatchEvent(
          new CustomEvent(
            'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
          )
        )
      },1000)
      window.addEventListener('sayduck.api-ready', (event) => {
        console.log('sayduck api ready')
        api = event.detail.instance
      });
    }
  }

  toggleLights = (lightsOn) => {
    if (isBrowser) {
      setTimeout(function(){
        let details = {...this.state.details}
        if(this.state.shadowSelectors && !lightsOn){
          let shadow_id = this.state.shadowSelectors.id
          details[shadow_id] = this.state.shadowSelectors.off_id
        }else if(this.state.shadowSelectors && lightsOn){
          let shadow_id = this.state.shadowSelectors.id
          details[shadow_id] = this.state.shadowSelectors.shadows[0].configurator_id
        }
        this.setState({
          lightsOn: lightsOn,
          details: details
        }, function(){
          if(isBrowser){
            window.dispatchEvent(
              new CustomEvent(
                'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
              )
            )
          }
        })
      }.bind(this),1000)
    }
  }

  photoTaken = (event) => {
    this.setState({
      image: event.detail.dataUrl
    }, function(){
      this.triggerPDFDownload()
    }.bind(this))
  }

  updateNotes = (e) => {
    this.setState({
      notes: e.target.value
    })
  }
  triggerImage = () => {
    if(isBrowser){
      this.setState({
        regenImage: true
      }, function(){
        takePhoto()
      })
    }
  }

  updateQuantity = (quantity) => {
    this.setState({
      quantity: quantity
    })
  }

  updateData = (object, previousSelection) => {
    let selectors = cloneDeep(this.state.selectors)
    let dimensionOverrides = cloneDeep(this.state.dimensionOverrides)
    let descriptionOverrides = cloneDeep(this.state.descriptionOverrides)
    let documents = []
    treeSelector(selectors, dimensionOverrides, descriptionOverrides, object, previousSelection)
    .then(function(selectorData){
      documents = filterExternalDocuments(this.state.product.external_documents, selectorData.metaData)
      this.setState({
        selectors: selectorData.selectors,
        limits: selectorData.limits,
        documents: documents,
        metaData: selectorData.metaData,
        dimensions: selectorData.dimensions
      }, function(){
        let details = buildSayduckSelections(selectorData.selectors)
        delete details[this.state.product.configurator_id]
        this.setState({
          details: details
        }, function(){
          if(this.state.lightsOffTriggers.length > 0) {
            this.state.lightsOffTriggers.forEach((item, i) => {
              let itemIndex = parseInt(item.smart_code_position)
              if(selectorData.metaData.smartCode[itemIndex] === item.smart_code){
                if(this.state.lightsOn){
                  this.toggleLights(false)
                }
              }
            })
          }
          for (var key in details) {
            try{
              api.configurator.setActiveByUuid(details[key])
            }catch(err){
              console.log(err)
            }
          }
          window.dispatchEvent(
            new CustomEvent(
              'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
            )
          )
          if(selectorData.resetCamera){
            setTimeout(function(){
              resetCamera(api)
            },500)
          }
        }.bind(this))
      }.bind(this))
    }.bind(this))
  }

  captureClick = (e) => {
    if(this.state.product.uses_light){
      let grandParentClassVal = e?.target?.parentNode?.parentNode?.classList?.value
      let parentClassVal = e?.target?.parentNode?.classList?.value
      let classVal = e?.target?.classList.value
      let grandChildClassVal = e?.target?.firstChild?.firstChild?.classList.value
      let lightsOnClass = 'sayduck-3d-viewer-145'
      let lightsOffClass = 'sayduck-3d-viewer-145 sayduck-3d-viewer-146'

      if(parentClassVal?.includes(lightsOffClass) ||
        classVal?.includes(lightsOffClass) ||
        grandChildClassVal?.includes(lightsOffClass) ||
        grandParentClassVal?.includes(lightsOffClass)){
        let details = {...this.state.details}
        if(this.state.shadowSelectors){
          let shadow_id = this.state.shadowSelectors.id
          details[shadow_id] = this.state.shadowSelectors.off_id
        }
        this.setState({
          lightsOn: false,
          details: details
        }, function(){
          if(isBrowser){
            window.dispatchEvent(
              new CustomEvent(
                'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
              )
            )
          }
        })
      }else if(parentClassVal?.includes(lightsOnClass) ||
        classVal?.includes(lightsOnClass) ||
        grandChildClassVal?.includes(lightsOnClass) ||
        grandParentClassVal?.includes(lightsOnClass)){
        let details = {...this.state.details}
        if(this.state.shadowSelectors){
          let shadow_id = this.state.shadowSelectors.id
          details[shadow_id] = this.state.shadowSelectors.shadows[0].configurator_id
        }
        this.setState({
          lightsOn: true
        }, function(){
          window.dispatchEvent(
            new CustomEvent(
              'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
            )
          )
        })
      }
    }
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  render() {
    let selectors = null
    if(this.state.selectors.length > 0){
      let descriptionOffset = 0
      selectors =  this.state.selectors.map((object, i)=>{
        let overrideDescription = ''

        if(object.smartCodePosition){
          if(this.state.metaData.smartDescription[parseInt(object.smartCodePosition)]){
            if(this.state.metaData.smartDescription[parseInt(object.smartCodePosition)].label === 'Dimension' &&
              !object.displayDimensions){
              descriptionOffset = 1
            }
          }
          if(this.state.metaData.smartDescription[parseInt(object.smartCodePosition) + descriptionOffset]){
            overrideDescription = this.state.metaData.smartDescription[parseInt(object.smartCodePosition) + descriptionOffset].description
          }
        }
        if(object.displayDimensions){
          overrideDescription = this.state.dimensions
        }
        return (<Selector option={object} key={i}
          updateData={this.updateData} limits={this.state.limits}
          overrideDescription={overrideDescription} metaData={this.state.metaData.smartDescription[i+descriptionOffset]} />)
      })
    }
    let smartCodeString = this.state.metaData.smartCode.join('-')
    let metaDescription = []
    if(this.state.metaData?.smartDescription){
      metaDescription = this.state.metaData.smartDescription.map((object, i)=>{
        let smartDescription = object.description
        if(object.customText){
          if(smartDescription.includes('Specify RAL/IGP Color')){
            smartDescription = smartDescription.replace('Specify RAL/IGP Color', object.customText)
            if(smartDescription?.split(/\\n/).length > 1){
              smartDescription = smartDescription.split(/\\n/).map((item, i) => {
                  return <span key={i}>{item}<br/></span>
              })
            }
          }else{
            let descriptions = smartDescription?.split(', ')
            smartDescription = descriptions.map((item, i) => {
              if(i === descriptions.length - 1){
                return <span key={i} className="customText">{object.customText}</span>
              }else{
                return <span key={i}>{item}, </span>
              }
            })
          }
        }else{
          if(smartDescription?.split(/\\n/).length > 1){
            smartDescription = smartDescription?.split(/\\n/).map((item, i) => {
                return <span key={i}>{item}<br/></span>
            })
          }
        }

        return <div key={i}><span className="red bold">{object.label}: </span> {smartDescription}</div>
      })
    }
    let defaultConfig = ''
    if(this.state.defaultConfiguration){
      defaultConfig = `, "configurations": {
          "initial": `+this.state.defaultConfiguration+`
      }`
    }
    return(
      <>
        <Seo title="Home" />
        <Link to={'/product-category/'+this.state.categoryPath}>Back to {this.state.product.product_category}</Link>
        <div style={{
          width: '100%',
          height: '50vh'
        }}>
          {this.state.product.use_updated_viewer &&
                <>
                <sayduck-viewer 
                product={this.state.product.configurator_id} 
                mode="configurator" 
                hide-picker  
                background='transparent'
                hide-branding
                hide-product-info
                hide-qr-code
                hide-web-ar
                hide-embed
                hide-fullscreen
                hide-dimensions
                hide-photo-studio
                initial-configuration={this.state.defaultConfiguration}
                ></sayduck-viewer>
                <Script src="https://viewer.sayduck.com" type="module" async />
                </>
          }
          {!this.state.product.use_updated_viewer &&
              <>
              <div id="sayduck-3d-viewer-container"
              aria-hidden
              className={(this.state.lightsOn ? 'lights-on' : 'lights-off')}
              style={{
                minHeight:'450px',
                minWidth:'300px',
                width:'100%',
                height:'100%',
                display: 'block',
                maxHeight: '50vh'
              }}
              ref={object => {
                this.sayduckViewer = object
              }}
              onClick={(e) => this.captureClick(e)}
              onKeyDown={(e) => this.captureClick(e)}
              data-product-uuid={this.state.product.configurator_id}
              hide-branding={true}
              data-viewer-options={'{"appearance":{ "hide-branding": true, "hide-logo": true, "hide-embed": true, "enable-qr-code": true, "background": "transparent", "hide-dimensions": false, "show-dimensions-on-load": true, "hide-photo-studio": true  }'+defaultConfig + '}'}
              />
              <Script src={"https://viewer.legacy.sayduck.com?ddd="+Date.now()} />
              </>
          }

        </div>
        

        <Invoice metaData={this.state.metaData}
        pricingOptions={this.state.pricingOptions}
        updateQuantity={this.updateQuantity} />
        <div className="row">
          <div className="col-sm-12">
            <form id="config-selector">
              {selectors}
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>{smartCodeString}</p>
            <div className="meta-description">
            {metaDescription}
            </div>
          </div>
        </div>
        {this.state.product?.website_link &&
          <div className="row" style={{marginTop: '2rem'}}>
            <div className="col-sm-12">
              <span className="red bold">Website Link: </span><a href={this.state.product.website_link} target="_blank" rel="noreferrer">{this.state.product.product_name}</a>
            </div>
          </div>
        }
        <div className="row" style={{marginTop: '2rem'}}>
        {this.state.documents.map( (document, i) => {
          return (<div className="col-sm-12" key={i}>
                    <span className="red bold">{document.document_type}: </span><a href={document.document_url} target="_blank" rel="noreferrer">{document.document_title}</a>
                  </div>)
        })}
          <div className="col-sm-12" style={{marginTop: '2rem', display: 'none'}}>
            <GlbLink details={this.state.details} liveVariants={this.state.liveVariants} title={''}  />
          </div>
          <div className="col-sm-12" style={{marginTop: '2rem'}}>
            <JSONDownload
            product={this.state.product}
            metaData={this.state.metaData}
            pricingOptions={this.state.pricingOptions}
            quantity={this.state.quantity} />
          </div>
        </div>
      </>
    )
  }
}

export default QC
