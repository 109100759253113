export const jsonOutput = (metaData, pricingData, product) => {
  let newMeta = {...metaData}
  delete newMeta.label
  newMeta.pricing = pricingData
  newMeta.id = ObjectId()
  newMeta.product_class = product.product_class
  let json = {
    configuratorItems: [newMeta],
    project : { id: ObjectId(), name: "My Project Name"}
  }
  return json
}

const ObjectId = (rnd = r16 => Math.floor(r16).toString(16)) =>
    rnd(Date.now()/1000) + ' '.repeat(16).replace(/./g, () => rnd(Math.random()*16));
