import React from 'react'
import QC from '../components/qc-2'
import Layout from '../components/layout'

const App = ({data, pageContext}) => {
  return(
    <Layout>
    <QC data={data} context={pageContext} />
    </Layout>
  )
}

export default App
