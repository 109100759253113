import React, { Component } from 'react'
import {
  formatPricing
} from '../utils/pricing'


class Invoice extends Component {
  constructor(props) {
    super(props)
    let mainQuantity = 1
    let prices = formatPricing(this.props.metaData, this.props.pricingOptions, mainQuantity)
    this.state = {
      prices: prices,
      mainQuantity: mainQuantity
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps) {

  }

  onQuantityChange = (e) => {
    let prices = formatPricing(this.props.metaData, this.props.pricingOptions, e.target.value)
    this.setState({
      mainQuantity: e.target.value,
      prices: prices
    }, function(){
      this.props.updateQuantity(this.state.mainQuantity)
    })
  }

  render() {
    let prices = formatPricing(this.props.metaData, this.props.pricingOptions, this.state.mainQuantity)
    let invoiceTotal = 0.0
    let weightTotal = 0.0
    let invoiceRows = prices.lineItems.map((option, i)=>{
      let quantity = 1
      if(option.quantity){
        quantity = parseInt(option.quantity)
      }

      if(i === 0){
        quantity = this.state.mainQuantity
        quantity = <input type="number" defaultValue={quantity} min={1} max={100} onChange={this.onQuantityChange} />
      }

      return(<div className="row" key={i}>
        <div className="col-sm-2">
        {option.model}
        </div>
        <div className="col-sm-4">
        {option.description}
        </div>
        <div className="col-sm-2 number-column">
        {quantity}
        </div>
        <div className="col-sm-2 number-column">
        {option.pricePrint}
        </div>
        <div className="col-sm-2 number-column">
        {option.weightPrint}
        </div>
      </div>)
    })
    invoiceTotal = prices.subTotalPrint
    weightTotal = prices.weightTotal
    return(<div style={{marginBottom: '2rem'}}>
      <div className="row">
        <div className="col-sm-12" style={{
          textAlign: 'center',
          color: '#fff',
          backgroundColor: '#333',
          marginTop: '2rem'
        }}>
          <strong>&nbsp;</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
        <div className="row" style={{
          backgroundColor: '#c0c0c0',
          marginBottom: '.5rem'
        }}>
          <div className="col-sm-2">
          <strong>Model</strong>
          </div>
          <div className="col-sm-4">
          <strong>Description</strong>
          </div>
          <div className="col-sm-2 number-column">
          <strong>Quantity</strong>
          </div>
          <div className="col-sm-2 number-column">
          <strong>Price Per</strong>
          </div>
          <div className="col-sm-2 number-column">
          <strong>Weight</strong>
          </div>
        </div>
        {invoiceRows}
        <div className="row">
          <div className="col-sm-8" style={{
            textAlign: 'right'
          }}>
          <strong>SubTotal:</strong>
          </div>
          <div className="col-sm-2 number-column">
          <strong>{invoiceTotal} USD</strong>
          </div>
          <div className="col-sm-2 number-column">
          <strong>{parseFloat(weightTotal).toFixed(2)} lbs.</strong>
          </div>
        </div>
        </div>
      </div>
      </div>
    )
  }
}

export default Invoice
