import React, { Component } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

class QCSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initOption: {...this.props.option},
      colorPicker: null,
      pickerMetaIndex: 0,
      customText: this.props.option.customText
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps) {
    if(prevProps.option.customText !== this.props.option.customText){
      this.setState({
        customText: this.props.option.customText
      })
    }
  }

  onTextChange = (event) => {
    let previousOption = {...this.state.initOption}
    this.props.option.customText = event.target.value
    this.setState({
      customText: event.target.value
    }, function(){
      this.props.updateData(this.props.option, previousOption)
    })
  }

  onSelect = (event) =>{
    let previousOption = {...this.state.initOption}
    let colorPicker = null
    let pickerMetaIndex = 0
    this.props.option.selected = event.target.value
    this.props.option.customText = ''
    // this.props.option.customDesc = customDesc
    this.setState({
      initOption: {...this.props.option},
      customText: '',
      colorPicker: colorPicker,
      pickerMetaIndex: pickerMetaIndex
    }, function(){
      this.props.updateData(this.props.option, previousOption)
    })
  }

  render() {
    let selectObjects = null
    let selectedClass = (this.props.option.selected ? 'selected' : '')
    if(this.props.option.hideSelector){
      selectedClass = ''
    }
    let colorPicker = null
    let selectedOption = this.props.option.options.find((option)=>{
      return option.value === this.props.option.selected
    })
    //find available limits and create an array of available option values
    let limits = null
    if(this.props.limits.length > 0){
      let availableLimits = this.props.limits.filter((limit)=>{
        return limit.parent_id === this.props.option.value
      })
      if(availableLimits.length > 0){
        limits = []
        availableLimits.forEach((limit, i) => {
          limit.smart_code_options.forEach((option, j) => {
            limits.push(option.configurator_id)
          })
        })
        //if the selected option is out of limits, select a valid option
        if(!limits.includes(this.props.option.selected) && this.props.option.selected){
          let validOptions = this.props.option.options.filter((option)=>{
            return limits.includes(option.value)
          })
          if(validOptions.length > 0){
            let previousOption = {...this.state.initOption}
            this.props.option.selected = validOptions[0].value
            this.props.updateData(this.props.option, previousOption)
            selectedOption = validOptions[0]
          }
        }
      }
    }
    if(selectedOption?.showColorPicker){
      colorPicker = <input type="text" className="customText" value={this.state.customText} placeholder="ENTER RAL COLOR VALUE" onChange={this.onTextChange} />
    }
    selectObjects = this.props.option.options.map((option, i)=>{
      if(limits){
        if(limits.includes(option.value)){
          return(<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
        }else{
          return null
        }
      }else{
        return(<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
      }
    })
    selectObjects = <Select value={this.props.option.selected || ''} onChange={this.onSelect}>
      {selectObjects}
    </Select>
    let sortStyle = {}
    if(this.props.option.sortOverride){
      sortStyle = {
        order: this.props.option.sortOverride
      }
    }
    let description = selectedOption?.smartDescription
    if(this.props.option?.overrideDescription){
      description = this.props.option.overrideDescription
    }
    if(this.props.overrideDescription){
      description = this.props.overrideDescription
    }
    if(!description){
      description = selectedOption?.smartDescription
    }
    if(this.state.customText){
      if(description.includes('Specify RAL/IGP Color')){
        description = description.replace('Specify RAL/IGP Color', this.state.customText)
        if(description.split(/\\n/).length > 1){
          description = description.split(/\\n/).map((item, i) => {
              return <span key={i}>{item}<br/></span>
          })
        }
      }else{
        let descriptions = description.split(', ')
        description = descriptions.map((item, i) => {
          if(i === descriptions.length - 1){
            return <span key={i} className="customText">{this.state.customText}</span>
          }else{
            return <span key={i}>{item}, </span>
          }
        })
      }
    }else{
      if(description){
        if(description.dimensions){
          description = description.dimensions
        }
        if(typeof description === 'object'){
          description = description.dimensions
        }
        if(description.split(/\\n/).length > 1){
          description = description.split(/\\n/).map((item, i) => {
              return <span key={i}>{item}<br/></span>
          })
        }
      }
    }

    return(<>
      {selectObjects && this.props.option.selected &&
        <div className={'viewSelectObject ' + selectedClass} style={sortStyle} data-value={this.props.option.value} data-selected={this.props.option.selected}>
          <div className="select-col uppercase">
            {this.props.option.label}
          </div>
          <div className="select-col-lg uppercase">
          {selectObjects}
          {colorPicker}
          </div>
          <div className="select-col-sm uppercase">
          {selectedOption?.smartCode}
          </div>
          <div className="select-col uppercase">
          {description}
          </div>
        </div>
      }
      </>
    )
  }
}

export default QCSelector
