import React, { Component } from 'react'
import {
  getVariantIds,
  generateCachedGlb,
  generateCachedUsdz
} from '../utils/sayduck'
const isBrowser = typeof window !== `undefined`

class GlbLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      glbLink: '',
      usdzLink: ''
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps){
      if(this.props.details){
        let variants = getVariantIds(this.props.details, this.props.liveVariants)
        generateCachedGlb(variants).then(glb => {
          generateCachedUsdz(variants).then(usdz => {
            this.setState({
              glbLink: glb,
              usdzLink: usdz
            })
          })
        })
      }
    }
  }

  render() {

    return(<>
      {this.state.glbLink && this.state.usdzLink && isBrowser &&
        <>
         <p><a href={this.state.usdzLink} rel="ar">download usdz</a></p>
         <p><a href={'intent://arvr.google.com/scene-viewer/1.0?file='+this.state.glbLink+'&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'}>download glb</a></p>
        </>
      }
      </>
    )
  }
}

export default GlbLink
