import React, { Component } from 'react'
import { jsonOutput } from '../utils/data-output'
import {
  formatPricing
} from '../utils/pricing'
const isBrowser = typeof window !== `undefined`

class JSONDownload extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  componentDidUpdate(){

  }

  downloadJSON = (e) => {
    let productName = this.props.metaData.smartDescription[0].description
    let pricingData = formatPricing(this.props.metaData, this.props.pricingOptions, this.props.quantity)
    let json = jsonOutput(this.props.metaData, pricingData, this.props.product)
    this.download(JSON.stringify(json, null, 2),
    productName.toLowerCase().replaceAll(' ', '-') + '.json',
    "text/plain")
  }

  download = (content, fileName, contentType) => {
    if(isBrowser){
      const a = document.createElement("a");
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }

  render() {
    return(
      <p><button onClick={this.downloadJSON}>Download JSON</button></p>
    )
  }
}

export default JSONDownload
